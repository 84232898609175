<template>
   <lz-dialog
    title="产品列表"
    :is-close-btn="false"
    :width="1300"
    ref="lzFormDialog">
    <template v-if="showPage">
      <lz-list
        :config="config"
        selection
        :table="table"
        @table-btn-click="handleCommonBtnCLick"
        @table-checkbox-change="handleCheckboxChange"
        :is-router="false"
        :searchFomate="searchFomate"
        :search="search"
        ref="lzList">
        <div slot="pagePosition" v-if="values.length > 0">
          <el-button @click="handleCommonBtnCLick('all')" style="margin-top: 5px;" type="primary" size="mini">批量选择</el-button>
        </div>
      </lz-list>
    </template>
  </lz-dialog>
</template>
<script>
import config from '@/config';

export default {
  props: {
    data: {
      default: () => ({
        list: {
          goods_ids: [],
          add_goods_ids: [],
        },
      }),
    },
    priceTitle: {
      default: null,
    },
    saleType: {
      default: null,
    },
    goodsList: {
      default: [],
    },
    type_id: {
      default: null,
    },
  },
  data() {
    return {
      values: [],
      config: {
        url: '/goods/list',
        setTableFormat: (list) => {
          list.forEach((el) => {
            this.$set(el, 'htmlButton', [
              {
                name: '确定',
                key: 'enter',
                type: 'primary',
              },
              {
                name: '预览',
                key: 'view',
                type: 'primary',
              },
            ]);
          });
          return list;
        },
      },
      search: [],
      table: [
        {
          name: '名称',
          prop: 'title',
          width: '200px',
          func: (ops) => `${ops.memo !== '' ? `<span class="memo">${ops.memo}</span>` : ''}${this.goodsList.some((r) => r.id === Number(ops.id)) ? '<span class="dot"></span>' : ''}${ops.title}`,
        },
        { name: '图片', type: 'image', prop: 'thumb', width: '150px' },
        // {
        //   name: '是否断货',
        //   prop: 'is_out_stock',
        //   func: (ops) => (!ops ? '是' : '否'),
        // },
        {
          name: '可发货时间',
          func: (ops) => `${ops.delivery_begin_time}-${ops.delivery_end_time}`,
          width: '200px',
        },
        // { name: '型号', prop: 'model' },
        // { name: '品牌', prop: 'brand_name' },
        { name: '自备货价', func: (ops) => (ops.supplier && ops.supplier.length > 0 ? ops.supplier[0].stocking_price : '-') },
        { name: '代发价', func: (ops) => (ops.supplier && ops.supplier.length > 0 ? ops.supplier[0].shipping_price : '-') },
        { name: '商城价', func: (ops) => (ops.supplier && ops.supplier.length > 0 ? ops.supplier[0].mall_price : '-') },
        { name: '税运', func: (ops) => (ops.supplier && ops.supplier.length > 0 ? ops.supplier[0].tax_type_name : '-') },
        { name: '操作', prop: 'htmlButton' },
      ],
      type: '',
      showPage: false,
    };
  },
  methods: {
    async init(type) {
      this.showPage = false;
      this.search = [
        { name: '名称/型号', type: 'text', fieldName: 'keyword', value: '' },
        {
          name: '品牌',
          type: 'select',
          fieldName: 'brand_id',
          value: '',
          remote: true,
          multiple: true,
          collapseTags: true,
          width: '180px',
          props: {
            url: '/brand/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '用途',
          type: 'select',
          fieldName: 'purpose_id',
          value: '',
          remote: true,
          props: {
            url: '/purpose/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '价格区间',
          type: 'select',
          fieldName: 'price_range_id',
          value: '',
          remote: true,
          props: {
            url: '/price-range/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '场景',
          type: 'select',
          fieldName: 'scene_id',
          value: '',
          remote: true,
          props: {
            url: '/scene/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '供应商',
          type: 'select',
          fieldName: 'supplier_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/supplier/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '价格',
          type: 'select',
          fieldName: 'price_title_id',
          value: '',
          remote: true,
          props: {
            url: '/price-title/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'cate_id',
          type: 'treeSelect',
          name: '品类',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/cate/list',
            value: 'id',
            label: 'name',
            params: { type: 1 },
          },
        },
        {
          name: '组合产品',
          placeholder: '组合产品',
          type: 'select',
          width: '110px',
          fieldName: 'is_combination',
          value: '',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
        },
        {
          name: '产品类型',
          placeholder: '产品类型',
          type: 'select',
          width: '110px',
          fieldName: 'productFomate',
          value: '',
          options: [
            { label: '直充产品', value: 'is_charge' },
            { label: '虚拟产品', value: 'is_virtual' },
            { label: '普通产品', value: 'is_normal' },
          ],
        },
        { name: '价格最小值', type: 'text', fieldName: 'shipping_price_min', value: '' },
        { name: '价格最大值', type: 'text', fieldName: 'shipping_price_max', value: '' },
        // { fieldName: 'is_out_stock', value: 0, hide: true },
        // { fieldName: 'is_stop', value: 0, hide: true },
        { fieldName: 'is_shelves', value: 1, hide: true },
        { fieldName: 'max_price_title_id', value: '', hide: true },
        { fieldName: 'sale_type', value: '', hide: true },
      ];
      this.$refs.lzFormDialog.dialogVisible = true;
      await new Promise((resolve) => {
        setTimeout(() => {
          this.showPage = true;
          resolve();
        }, 100);
      });
      this.type = type;
      console.log(this.type_id);
      this.search.filter((r) => ['max_price_title_id', 'price_title_id', 'sale_type'].includes(r.fieldName)).forEach((el) => {
        if (el.fieldName === 'max_price_title_id' && (this.$route.name !== 'ticket-list-recharge' && this.type_id.value !== '9')) {
          this.$set(el, 'value', this.priceTitle.value);
          return;
        }
        if (el.fieldName === 'sale_type') {
          this.$set(el, 'value', this.saleType.value);
          if (this.$route.name === 'ticket-list-recharge' || (this.type_id !== undefined && this.type_id.value === '9')) {
            this.$set(el, 'value', '');
          }
          return;
        }
        if (this.$route.name !== 'ticket-list-recharge') {
          const interval = setInterval(() => {
            if (el.options && el.options.length > 0) {
              clearInterval(interval);
              const target = el.options.find((r) => String(r.value) === String(this.priceTitle.value));
              this.$set(el, 'options', el.options.map((r) => ({
                ...r,
                disabled: Number(r.price) > Number(target.price),
              })));
            }
          }, 500);
        }
      });
      this.$nextTick(() => {
        this.$refs.lzList.getData();
      });
    },
    handleCommonBtnCLick(type, element) {
      if (type === 'all') {
        let total = 0;
        this.values.forEach((el) => {
          const hasGoods = this.goodsList.some((r) => r.id === Number(el.id));
          if (hasGoods) return;
          this.data.list[this.type].push({
            id: el.id,
            thumb: el.thumb,
            title: el.title,
          });
          total += 1;
        });
        this.$message.success(`已添加${total}个产品`);
        return;
      }
      if (type === 'view') {
        window.open(`${config.giftUrl}/details/${element.id}`);
        return;
      }
      const hasGoods = this.goodsList.some((r) => r.id === Number(element.id));
      if (hasGoods) {
        this.$message.error('该产品已存在');
        return;
      }
      this.data.list[this.type].push({
        id: element.id,
        thumb: element.thumb,
        title: element.title,
      });
      this.$message.success('添加成功');
    },
    handleCheckboxChange(values) {
      this.values = values;
    },
    searchFomate(params) {
      if (params.productFomate === 'is_charge') {
        this.$set(params, 'is_charge', 1);
      } else if (params.productFomate === 'is_virtual') {
        this.$set(params, 'is_virtual', 1);
      } else if (params.productFomate === 'is_normal') {
        this.$set(params, 'is_charge', 0);
        this.$set(params, 'is_virtual', 0);
      }
      this.$delete(params.productFomate);
      return params;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgb(200, 21, 32);
  display: inline-block;
  margin-right: 5px;
}
::v-deep .memo {
  background: rgb(200, 21, 32);
  color: #fff;
  padding: 0 5px;
  display: inline-block;
  margin-right: 5px;
}
</style>

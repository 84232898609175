<template>
  <div>
    <!-- <el-alert
      title="产品打勾表示断货"
      type="warning"
      show-icon>
    </el-alert> -->
    <lz-card class="mt-20" title="产品列表">
      <el-button :disabled="!priceTitle || priceTitle.value == ''"  type="primary" slot="right" @click="$refs.productList.init('goods_ids')">新增</el-button>
      <draggable v-model="data.list.goods_ids" data-flex="" style="flex-wrap: wrap">
        <div v-for="(element, index) in data.list.goods_ids" :key="index">
          <product-item :element="element" :index="index" :list="data.list.goods_ids" @preview-product="handlePreview"></product-item>
        </div>
      </draggable>
    </lz-card>
    <lz-card  class="mt-20" title="新增产品" v-if="$route.name !== 'ticket-list-recharge'">
      <el-button :disabled="!priceTitle || priceTitle.value == ''" type="primary" slot="right" @click="$refs.productList.init('add_goods_ids')">新增</el-button>
      <draggable v-model="data.list.add_goods_ids" data-flex="" style="flex-wrap: wrap">
        <div v-for="(element, index) in data.list.add_goods_ids" :key="index">
          <product-item :element="element" :index="index"  :list="data.list.add_goods_ids" @preview-product="handlePreview"></product-item>
        </div>
      </draggable>
    </lz-card>
    <lz-card title="兑换限制" v-if="goodsList.length > 0">
      <el-button
        :disabled="data.list.goods_limit.length == goodsList.length"
        slot="right"
        type="primary"
        @click="data.list.goods_limit.push({
          id: '',
          time_unit: 1,
          num_limit: 0,
        })">新增</el-button>
      <el-table
        :data="data.list.goods_limit"
        :header-cell-style="{backgroundColor: '#f6f8fa'}"
        border>
        <el-table-column label="产品">
          <template slot-scope="scope">
            <el-select
              filterable
              style="width: 100%;"
              v-model="scope.row.id">
              <el-option
                v-for="item in goodsList"
                :disabled="data.list.goods_limit.some(v => v.id == item.id)"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="时间">
          <template slot-scope="scope">
            <el-select
              filterable
              style="width: 100%;"
              v-model="scope.row.time_unit">
              <el-option label="天" :value="1"></el-option>
              <el-option label="周" :value="2"></el-option>
              <el-option label="月" :value="3"></el-option>
              <el-option label="年" :value="4"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="限制兑换数">
          <template slot-scope="scope">
            <el-input-number :min="0" v-model="scope.row.num_limit" clearable placeholder="快递费用" style="width: 100%;"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-link
              :underline="false"
              @click="data.list.goods_limit.splice(scope.$index, 1)"
              type="danger">
              删除
            </el-link>
          </template>
        </el-table-column>
      </el-table>
    </lz-card>
    <div></div>
    <product-list ref="productList" :goodsList="goodsList" :data="data" :priceTitle="priceTitle" :saleType="saleType" :type_id="type_id"></product-list>
    <preview ref="preview"></preview>
  </div>
</template>
<script>
import config from '@/config';
import draggable from 'vuedraggable';
import preview from '@/views/product/preview';
import productItem from './productItem';
import productList from './productList';

export default {
  components: {
    productItem,
    draggable,
    productList,
    preview,
  },
  props: {
    data: {
      default: () => ({
        list: {
          goods_ids: [],
          add_goods_ids: [],
        },
      }),
    },
    priceTitle: {
      default: null,
    },
    saleType: {
      default: null,
    },
    type_id: {
      default: null,
    },
  },
  computed: {
    goodsList() {
      return [
        ...this.data.list.goods_ids,
        ...this.data.list.add_goods_ids,
      ];
    },
  },
  watch: {
    'priceTitle.value': function change(val, oldval) {
      if (this.priceTitleId === val || oldval === undefined || val === undefined) return;
      this.priceTitleId = oldval;
      if (val !== '' && oldval !== '') {
        this.$baseConfirm({
          content: '改变礼一价格将会清空产品',
          callConfirm: async () => {
            this.data.list.goods_ids = [];
            this.data.list.add_goods_ids = [];
            this.data.list.goods_limit = [];
          },
          callCancel: () => this.$set(this.priceTitle, 'value', oldval),
        });
      }
    },
  },
  data() {
    return {
      priceTitleId: '',
    };
  },
  methods: {
    handlePreview(id) {
      window.open(`${config.giftUrl}/details/${id}`);
      // this.$refs.preview.init(id);
    },
  },
};
</script>

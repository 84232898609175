export default {
  methods: {
    initReCharge() {
      this.setRechargeSearch();
      this.setRechargeTable();
      const type = this.search.find((r) => r.fieldName === 'type_id');
      this.$set(type, 'value', '9');
      this.$set(type, 'hide', true);
      this.$set(type, 'noClear', true);
    },
    setRechargeTable() {
      this.table = [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '客户名称', prop: 'company_name' },
        { name: '销售渠道', prop: 'sale_type_name', width: '130px' },
        { name: '产品线', prop: 'ticket_line_name', width: '130px' },
        { name: '载体类型', prop: 'carrier_type_name', width: '130px' },
        { name: '页面标题', prop: 'webtitle' },
        { name: '年份', prop: 'year' },
        { name: '场景', prop: 'scene_name' },
        { name: '用途', prop: 'purpose_name' },
        { name: '总金额', prop: 'total_amount' },
        { name: '余额', prop: 'balance' },
        { name: '创建时间', prop: 'create_time' },
        { name: '兑换码数量', prop: 'ticket_num' },
        { name: '操作', prop: 'htmlButton' },
      ];
    },
    setRechargeSearch() {
      this.search = [
        {
          name: '客户名称',
          type: 'select',
          fieldName: 'company_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/company/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'sale_type',
          type: 'select',
          name: '销售渠道',
          value: '',
          options: [],
          constantDict: 'saleType',
        },
        {
          name: '分销商',
          type: 'select',
          fieldName: 'agent_id',
          value: [],
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/agent/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'ticket_line_id',
          type: 'select',
          name: '产品线',
          value: '',
          options: [],
          remote: true,
          width: '150px',
          props: {
            url: '/ticket-line/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'carrier_type',
          type: 'select',
          name: '载体类型',
          width: '150px',
          value: '',
          options: [],
          constantDict: 'carriertype',
        },
        { name: '页面标题', type: 'text', fieldName: 'webtitle', value: '' },
        { name: '年份', type: 'date', fieldName: 'year', value: '', format: 'yyyy', dateType: 'year' },
        {
          name: '场景',
          type: 'select',
          fieldName: 'scene_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/scene/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '用途',
          type: 'select',
          fieldName: 'purpose_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/purpose/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '订单类型',
          type: 'select',
          fieldName: 'type_id',
          value: [],
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/ticket-type/list',
            value: 'id',
            label: 'name',
          },
        },
      ];
    },
  },
};

<template>
  <div>
    <lz-list
      :config="config"
      :table="table"
      :search="search"
      ref="lzList"
      @search-btn-click="handleCommonBtnCLick"
      @table-btn-click="handleCommonBtnCLick">
    </lz-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :title="title"
      ref="lzFormDialog">
      <template v-for="parent in form">
        <template v-if="parent.fieldName == 'attachment'">
          <lz-form
            :key="parent.fieldName"
            :form="parent.list"
            ref="lzForm"
            :col="1">
          </lz-form>
        </template>
        <template v-else-if="parent.fieldName == 'goods-list'">
          <goods-list
            :type="type"
            :key="parent.fieldName"
            :data="parent"
            :type_id="type_id"
            :saleType="saleType"
            :priceTitle="$route.name == 'ticket-list-recharge' ? -1 : priceTitle"
            ref="lzForm">
          </goods-list>
        </template>
        <lz-card
          v-else
          :title="parent.name"
          :key="parent.fieldName">
          <lz-form :form="parent.list" ref="lzForm" :col="3" @change-select="handleChangeSelect"></lz-form>
        </lz-card>
      </template>
    </lz-dialog>
    <lz-dialog
      title="在线地址"
      :width="500"
      :is-close-btn="false"
      ref="lzQrcodeDialog">
      <div data-flex="main:center dir:top cross:center" class="mb-20" v-if="templateUrl">
        <div>模版二维码</div>
        <div class="qrcode-wrap1">
          <qrcode ref="qrcode" :value="templateUrl" :options="{ width: 200 }"></qrcode>
        </div>
        <el-input
          v-model="templateUrl"
          :disabled="true">
          <template slot="append">
            <el-button icon="el-icon-document-copy" @click="handleCopy(templateUrl, $event)">复制地址</el-button>
            <el-button icon="el-icon-document-copy" @click="qrType = 1;$refs.lzProductDialog.dialogVisible = true">查看</el-button>
            <el-button icon="el-icon-document-copy" @click="handleCopyDown(1)">下载</el-button>
          </template>
        </el-input>
      </div>
      <div data-flex="main:center dir:top cross:center" v-if="qrcodeUrl">
        <div>列表二维码</div>
        <div class="qrcode-wrap2">
          <qrcode ref="qrcode" :value="qrcodeUrl" :options="{ width: 200 }"></qrcode>
        </div>
        <el-input
          v-model="qrcodeUrl"
          :disabled="true">
          <template slot="append">
            <el-button icon="el-icon-document-copy" @click="handleCopy(qrcodeUrl, $event)">复制地址</el-button>
            <el-button icon="el-icon-document-copy" @click="qrType = 2;$refs.lzProductDialog.dialogVisible = true">查看</el-button>
            <el-button icon="el-icon-document-copy" @click="handleCopyDown(2)">下载</el-button>
          </template>
        </el-input>
      </div>
    </lz-dialog>
    <lz-dialog
      title="在线预览"
      :width="500"
      :is-close-btn="false"
      ref="lzProductDialog">
      <div data-flex="main:center" style="height: 600px">
        <iframe scrolling="yes" frameborder="0" :src="qrType == 1 ? templateUrl : qrcodeUrl" style="height: 100%;width: 320px"></iframe>
      </div>
    </lz-dialog>
    <lz-dialog
      title="添加记录"
      :is-close-btn="false"
      ref="lzDialog">
      <lz-table-list
        :table="[
          { name: '新增数量', prop: 'ticket_num' },
          { name: '兑换数量', prop: 'exchange_num' },
          { name: '添加时间', prop: 'create_time' },
          { name: '操作人', prop: 'nick_name' },
        ]"
        :list="log">
      </lz-table-list>
    </lz-dialog>
    <add-code ref="addCode" @success="$refs.lzList.getData()"></add-code>
    <goods-log ref="goodsLog" ></goods-log>
    <design ref="design"></design>
  </div>
</template>
<script>
import exportPpt from '@/mixin/exportPpt';
import BigNumber from 'bignumber.js';
import { getSearchValue } from '@/utils/search';
import { formGetValue } from '@/utils/form';
import { getAccessToken } from '@/utils/auth';
import cfg from '@/config';
import clip from '@/utils/clipboard';
import qrcode from '@chenfengyuan/vue-qrcode';
import addCode from './addCode.vue';
import form from './form';
import formRecharge from './formRecharge';
import design from '../template/design';
import goodsList from './goodsList';
import goodsLog from './goodsLog';
import recharge from './mixins/recharge';

const template = [
  { label: '兑换券', value: 1 },
  { label: '礼品券', value: 2 },
];
export default {
  mixins: [exportPpt, recharge],
  components: {
    qrcode,
    addCode,
    goodsList,
    goodsLog,
    design,
  },
  data() {
    return {
      type: '',
      search: [
        // { name: '客户名称', type: 'text', fieldName: 'webtitle', value: '' },
        {
          name: '客户名称',
          type: 'select',
          fieldName: 'company_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/company/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '订单类型',
          type: 'select',
          fieldName: 'type_id',
          value: [],
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/ticket-type/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'ticket_line_id',
          type: 'select',
          name: '产品线',
          value: '',
          options: [],
          remote: true,
          width: '150px',
          props: {
            url: '/ticket-line/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'carrier_type',
          type: 'select',
          name: '载体类型',
          width: '150px',
          value: '',
          options: [],
          constantDict: 'carriertype',
        },
        { name: '年份', type: 'date', fieldName: 'year', value: '', format: 'yyyy', dateType: 'year' },
        {
          name: '场景',
          type: 'select',
          fieldName: 'scene_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/scene/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '用途',
          type: 'select',
          fieldName: 'purpose_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/purpose/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'price_title_id',
          type: 'select',
          name: '档位',
          value: '',
          options: [],
          remote: true,
          props: {
            url: '/price-title/list',
            value: 'id',
            label: 'name',
          },
        },
        // {
        //   fieldName: 'sale_type',
        //   type: 'select',
        //   name: '销售渠道',
        //   width: '150px',
        //   value: '',
        //   options: [],
        //   constantDict: 'saleType',
        // },
        {
          fieldName: 'template_id',
          type: 'select',
          name: '模版',
          value: '',
          options: [],
          remote: true,
          props: {
            url: '/template/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'type',
          type: 'select',
          name: '订单属性',
          value: '',
          options: template,
        },
        // {
        //   name: '订单类型',
        //   type: 'select',
        //   fieldName: 'type_id',
        //   value: [],
        //   options: [],
        //   remote: true,
        //   props: {
        //     url: '/ticket-type/list',
        //     value: 'id',
        //     label: 'name',
        //   },
        // },
        // { name: '创建', type: 'daterange', fieldName: 'create_begin_time||create_end_time', value: [], width: '300px' },
      ],
      config: {
        url: '/ticket/list',
        setTableFormat: (list) => {
          list.forEach((el) => {
            if (el.btn_code) el.htmlButton.push({ name: '兑换码', key: 'code', type: 'warning' });
            if (el.btn_import) el.htmlButton.push({ name: '新增卡券', key: 'import', type: 'danger' });
            if (el.btn_log) el.htmlButton.push({ name: '添加记录', key: 'add-log', type: 'success' });
            if (el.btn_copy) el.htmlButton.push({ name: '复制订单', key: 'copy', type: 'primary' });
            el.htmlButton.push({ name: '查看地址', key: 'qrcode', type: 'primary' });
            el.htmlButton.push({ name: '导出ppt', key: 'exportPPT', type: 'primary' });
            el.htmlButton.push({ name: '导出', key: 'ticket_export', type: 'primary' });
            if (el.last_goods_time) el.htmlButton.push({ name: '调品记录', key: 'goodLog', type: 'primary' });
          });
          return list;
        },
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '客户名称', prop: 'company_name' },
        { name: '销售渠道', prop: 'sale_type_name', width: '130px' },
        { name: '订单类型', prop: 'type_name' },
        { name: '产品线', prop: 'ticket_line_name', width: '130px' },
        { name: '载体类型', prop: 'carrier_type_name', width: '130px' },
        { name: '年份', prop: 'year' },
        { name: '场景', prop: 'scene_name' },
        { name: '用途', prop: 'purpose_name' },
        { name: '档位', prop: 'price_title_name' },
        { name: '页面标题', prop: 'webtitle' },
        // {
        //   name: '类型',
        //   func: (ops) => template.find((r) => r.value === ops.type).label,
        // },
        { name: '模板名称', prop: 'template_name' },
        { name: '创建时间', prop: 'create_time' },
        // { name: '更新时间', prop: 'update_time' },
        // { name: '调品时间', prop: 'last_goods_time' },
        { name: '兑换码数量', prop: 'ticket_num' },
        { name: '操作', prop: 'htmlButton' },
      ],
      form: [],
      dialogBtns: [],
      title: '',
      qrcodeUrl: '',
      templateUrl: '',
      log: [],
      priceTitle: null,
      saleType: null,
      qrType: null,
      lock: true,
      allList: [],
    };
  },
  computed: {
    type_id() {
      return this.form[0].list.find((r) => r.fieldName === 'type_id');
    },
  },
  watch: {
    form: {
      handler: function change() {
        if (this.lock || this.$route.name === 'ticket-list-recharge') return;
        // 销售金额 sales_amount
        const salesAmout = this.allList.find((r) => r.fieldName === 'sales_amount');
        // 总金额
        const totalAmount = this.allList.find((r) => r.fieldName === 'total_amount');
        // 折扣
        const discount = this.allList.find((r) => r.fieldName === 'discount');
        // 兑换码数量
        const ticketNum = this.allList.find((r) => r.fieldName === 'ticket_num');
        // 档位
        const priceTitleId = this.allList.find((r) => r.fieldName === 'price_title_id');
        // 销售金额前端根据档位中的字段market_price*折扣字段计算出
        // market_price
        const marketPrice = priceTitleId.options.find((r) => String(r.value) === String(priceTitleId.value));
        if (marketPrice) {
          this.$set(salesAmout, 'value', BigNumber(Number(marketPrice.market_price)).times((discount.value / 100)).toNumber());
          this.$set(totalAmount, 'value', BigNumber(salesAmout.value).times(ticketNum.value).toNumber());
        }
      },
      deep: true,
    },
  },
  created() {
    const name = this.$route.name;
    if (name === 'ticket-list-recharge') {
      this.config.url = '/ticket/list?is_deal=1';
      this.initReCharge();
    }
    const type = this.search.find((r) => r.fieldName === 'type_id');
    const company = this.search.find((r) => r.fieldName === 'company_id');
    if (name === 'ticket-thank' || name === 'ticket-birth') {
      this.$set(type, 'value', name === 'ticket-thank' ? '1' : '7');
      this.$set(type, 'hide', true);
      this.$set(type, 'noClear', true);
    }
    if (name === 'ticket-public') {
      this.$set(company, 'value', '9');
      this.$set(company, 'hide', true);
      this.$set(company, 'noClear', true);
    }
    if (name === 'ticket-list' || name === 'ticket-list-fx') {
      this.config.url = `/ticket/list?is_deal=1&sale_type=${name === 'ticket-list' ? 1 : 2}`;
    }
    if (name === 'ticket-list-fx') {
      this.search.unshift({
        name: '分销商',
        type: 'select',
        fieldName: 'agent_id',
        value: '',
        options: [],
        remote: true,
        props: {
          url: '/agent/list',
          value: 'id',
          label: 'name',
        },
      });
    }
    if (name === 'ticket-not-deal') {
      this.config.url = '/ticket/list?is_deal=0';
      company.props.url = '/company/list?is_deal=';
    }
    this.form = [];
    setTimeout(() => {
      this.form = this.$baseDeepClone(name === 'ticket-list-recharge' ? formRecharge : form);
      this.form[0].list[0].props.url = `/company/list?is_deal=${this.$route.name === 'ticket-not-deal' ? '' : 1}`;
    }, 100);
  },
  methods: {
    handleTableBtnClick(type, element) {
      this.type = type;
      const lzList = this.$refs.lzList;
      switch (type) {
        case 'edit':
          this.handleDialogForm(element);
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`/holiday/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('删除成功');
              lzList.getData();
            },
          });
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleCommonBtnCLick(type, element) {
      this.type = type;
      this.targetEle = element;
      switch (type) {
        case 'save': {
          const allError = [];
          let data = {};
          this.form.forEach((parent) => {
            if (parent.fieldName === 'goods-list') {
              data.goods_ids = parent.list.goods_ids.map((v) => v.id).toString();
              data.add_goods_ids = parent.list.add_goods_ids.map((v) => v.id).toString();
              data.goods_limit = parent.list.goods_limit.filter((v) => v.id !== '');
              data.out_goods_ids = [];
              [
                ...parent.list.goods_ids,
                ...parent.list.add_goods_ids,
              ].forEach((r) => {
                if (r.is_global === 1 || r.is_global === 0) {
                  data.out_goods_ids.push(r.id);
                }
              });
              return;
            }
            const { params, error } = formGetValue(parent.list);
            allError.push(...error);
            data = Object.assign(params, data);
          });
          if (allError.length > 0) {
            this.$message.error(`${allError[0].name}格式有误`);
            return;
          }
          data.time = [data.begin_time, data.end_time];
          if (data.source_id === '0' || data.source_id === '') {
            this.$delete(data, 'source_id');
          }
          const response = await this.$baseHttp.post('/ticket/save', data);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzFormDialog.dialogVisible = false;
          // if (this.$route.name === 'ticket-public' && !data.id) {
          //   this.$router.push({
          //     name: 'ticket-list',
          //   });
          //   return;
          // }
          this.$refs.lzList.getData();
        }
          break;
        case 'add':
          this.handleDialogForm();
          break;
        case 'copy':
        case 'edit': {
          const response = await this.$baseHttp.get('/ticket/detail', {
            params: { id: element.id },
          });
          this.$set(response.data, 'share_attachment_id', response.data.share_attachment_id === 0 ? [] : [response.data.share_attachment]);
          this.$set(response.data, 'login_attachment_id', response.data.login_attachment_id === 0 ? [] : [response.data.login_attachment]);
          this.$set(response.data, 'bg_id', response.data.bg_id === 0 ? [] : [response.data.bg]);
          this.$set(response.data, 'logo_id', response.data.logo_id === 0 ? [] : [response.data.logo]);
          if (type === 'copy') {
            this.$baseConfirm({
              content: '是否复制id?',
              confirmText: '是',
              cancalText: '否',
              callConfirm: async () => {
                this.$set(response, 'data', {
                  source_id: response.data.id,
                  type: response.data.type,
                  year: response.data.year,
                  exchange_num: response.data.exchange_num,
                  price_title_id: response.data.price_title_id,
                  scene_id: response.data.scene_id,
                  template_id: response.data.template_id,
                  goods: response.data.goods,
                  add_goods: response.data.add_goods,
                  out_goods: response.data.out_goods,
                  attachment: response.data.attachment,
                  bg_id: response.data.bg_id,
                  type_id: this.$route.name === 'ticket-list-recharge' ? 9 : '',
                });
                this.handleDialogForm(response.data, type);
              },
              callCancel: () => {
                this.$set(response, 'data', {
                  type: response.data.type,
                  year: response.data.year,
                  exchange_num: response.data.exchange_num,
                  price_title_id: response.data.price_title_id,
                  scene_id: response.data.scene_id,
                  template_id: response.data.template_id,
                  goods: response.data.goods,
                  add_goods: response.data.add_goods,
                  out_goods: response.data.out_goods,
                  attachment: response.data.attachment,
                  bg_id: response.data.bg_id,
                  type_id: this.$route.name === 'ticket-list-recharge' ? 9 : '',
                });
                this.handleDialogForm(response.data, type);
              },
            });
            return;
          }
          this.handleDialogForm(response.data, type);
        }
          break;
        case 'add-log': {
          const response = await this.$baseHttp.get('/ticket/logs', {
            params: { id: element.id },
          });
          this.log = response.data;
          this.$refs.lzDialog.dialogVisible = true;
        }
          break;
        case 'goodLog':
          this.$refs.goodsLog.init(element.id);
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`/ticket/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('删除成功');
              this.$refs.lzList.getData();
            },
          });
          break;
        case 'import':
          this.$refs.addCode.init(element);
          break;
        case 'export': {
          const query = getSearchValue(this.search);
          query.is_export = 1;
          query.token = getAccessToken();
          delete query.page;
          delete query.page_size;
          window.open(`${cfg.api}/ticket/list?${this.$jsonToUrl(query)}`);
        }
          break;
        case 'ticket_export':
          window.open(`${cfg.api}/ticket/goods/lists?${this.$jsonToUrl({
            is_export: 1,
            token: getAccessToken(),
            ticket_id: element.id,
          })}`);
          break;
        case 'qrcode':
          if (element.type === 1) {
            this.qrcodeUrl = `${cfg.giftUrl}/login?id=${element.id}`;
            this.templateUrl = '';
          } else {
            this.qrcodeUrl = `${cfg.giftUrl}/gift/list/${element.source_id || element.id}`;
            this.templateUrl = `${cfg.giftUrl}/h5?ticket_id=${element.source_id || element.id}`;
          }
          this.$refs.lzQrcodeDialog.dialogVisible = true;
          break;
        case 'code':
          this.$router.push({
            name: element.type_id === 9 ? 'code-recharge' : 'code-list',
            query: {
              ticket_id: element.id,
              company_id: String(element.company_id),
              year: `${element.year}-12-31`,
              price_title_id: String(element.price_title_id),
              scene_id: String(element.scene_id),
            },
          });
          break;
        case 'reset-template':
        case 'template': {
          const other = this.form.find((r) => r.fieldName === (this.$route.name !== 'ticket-list-recharge' ? 'other' : 'base'));
          const id = other.list.find((r) => r.fieldName === 'id');
          const template = other.list.find((r) => r.fieldName === 'template_id');
          const content = other.list.find((r) => r.fieldName === 'content');
          if (type === 'reset-template') {
            this.$set(content, 'value', '');
          }
          if (template.value === '' && content.value === '') {
            this.$message.error('请选择模版');
            return;
          }
          if (content.value === '') {
            const params = {
              id: template.value,
            };
            const response = await this.$baseHttp.get('/template/detail', { params });
            if (!response) return;
            this.$set(content, 'value', response.data.content);
          }
          this.$refs.design.init({
            id: id.value,
            content: content.value,
          });
        }
          break;
        case 'exportPPT': {
          const response = await this.$baseHttp.get('/ticket/detail', {
            params: { id: element.id },
          });
          const list = [...response.data.goods, ...response.data.add_goods];
          this.handleExportPPT(list);
        }
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response = null, type = 'add') {
      this.lock = true;
      let title = '';
      this.dialogBtns = [];
      switch (type) {
        case 'edit':
          title = '修改';
          break;
        case 'add':
          title = '新增';
          break;
        default:
          title = '复制';
          break;
      }
      this.title = title + this.$route.meta.title;
      this.$refs.lzFormDialog.dialogVisible = true;
      if (response) {
        this.$set(response, 'attachment', Array.isArray(response.attachment) ? [] : [response.attachment]);
      }
      if (response
        && (response.source_id === response.id || response.source_id === 0)
        && response.btn_template_save) {
        this.dialogBtns.push({ key: 'template', name: '编辑模版', type: 'primary' });
        this.dialogBtns.push({ key: 'reset-template', name: '重置模版', type: 'warning' });
      }
      this.dialogBtns.push({ key: 'save', name: '保存', type: 'primary' });
      this.allList = [];
      this.form.forEach((parent, index) => {
        if (Array.isArray(parent.list)) this.allList.push(...parent.list);
        this.$nextTick(() => {
          if (parent.fieldName === 'goods-list') {
            this.$set(parent.list, 'goods_limit', []);
            if (response) {
              this.$set(parent.list, 'goods_ids', response.goods);
              this.$set(parent.list, 'add_goods_ids', response.add_goods);
              // 判断是否存在断货和兑现限制
              this.checkGoods(parent.list, 'goods_ids', response.out_goods);
              this.checkGoods(parent.list, 'add_goods_ids', response.out_goods);
            } else {
              this.$set(parent.list, 'goods_ids', []);
              this.$set(parent.list, 'add_goods_ids', []);
            }
            return;
          }
          if (parent.fieldName === 'base') {
            parent.list.filter((r) => ['ticket_num', 'exchange_num', 'begin_time||end_time'].includes(r.fieldName)).forEach((el) => {
              this.$set(el, 'disabled', response !== null && type !== 'copy');
              if (el.fieldName === 'exchange_num') {
                this.$set(el, 'disabled', !(response && response.btn_exchange_num) && type === 'edit');
              }
              if (el.fieldName === 'ticket_num'
              && response === null && type === 'edit') {
                this.$set(el, 'disabled', this.$route.meta.roleBtn['add-num'] !== 1);
              }
              if (el.fieldName === 'begin_time||end_time') {
                this.$set(el, 'required', response.type_id !== 9);
              }
            });
            if (this.$route.name === 'ticket-list-recharge') {
              parent.list.forEach((el) => {
                if (el.fieldName === 'type_id') this.$set(el, 'hide', true);
                if (el.fieldName === 'sale_type') this.saleType = el;
              });
            }
          }
          // 取出礼一价格用来判断添加产品的价位
          if (parent.fieldName === 'other') {
            this.priceTitle = null;
            this.saleType = null;
            setTimeout(() => {
              this.priceTitle = parent.list.find((r) => r.fieldName === 'price_title_id');
              this.saleType = parent.list.find((r) => r.fieldName === 'sale_type');
            }, 500);
            const template = parent.list.find((r) => r.fieldName === 'template_id');
            this.$set(template, 'disabled', this.dialogBtns.length === 1 && type !== 'add');
          }
          this.$refs.lzForm[index].setValue({
            data: response || {
              type_id: this.$route.name === 'ticket-list-recharge' ? '9' : '',
            },
          });
        });
      });
      setTimeout(() => { this.lock = false; }, 1000);
    },
    checkGoods(list, type, outGoods) {
      list[type].forEach((element) => {
        // 判断是否存在断货
        const target = outGoods.find((r) => r.id === element.id);
        if (target) {
          this.$set(element, 'memo', target.memo);
          this.$set(element, 'is_global', target.is_global);
        }
        if (element.time_unit !== 0) {
          list.goods_limit.push({
            id: element.id,
            time_unit: element.time_unit,
            num_limit: element.num_limit,
          });
        }
      });
    },
    handleCopy(text, event) {
      clip(text, event);
    },
    handleCopyDown(index) {
      const qrcodeWrap = document.querySelector(`.qrcode-wrap${index}`).childNodes[0];
      const a = document.createElement('a');
      a.download = this.targetEle.webtitle;
      a.href = qrcodeWrap.toDataURL();
      a.dispatchEvent(new MouseEvent('click'));
    },
    handleChangeSelect(element) {
      if (element.fieldName === 'price_title_id') {
        this.priceTitle = element;
      }
      if (element.fieldName === 'sale_type') {
        this.saleType = element;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>

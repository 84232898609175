<template>
  <div class="lz-upload" :class="{out: element.is_global === 1 || element.is_global === 0}">
    <img :src="element.thumb" />
    <div class="action" @click="handlePreview(element.id)">
      <!-- <i
        @click.stop="handleOutPro(element)"
        v-if="element.is_global !== 1"
        :class="element.is_global !== 1 && element.is_global !== 0 ? 'el-icon-success' : 'el-icon-error'">
      </i> -->
      <i class="el-icon-delete" @click.stop="list.splice(index, 1)"></i>
    </div>
    <div class="title">{{ element.title }}</div>
    <div v-if="element.memo" class="memo">{{ element.memo }}</div>
  </div>
</template>
<script>
export default {
  props: {
    element: {
      defualt: {},
    },
    index: {
      default: 0,
    },
    list: {
      default: () => [],
    },
  },
  methods: {
    handleOutPro(element) {
      switch (element.is_global) {
        case 1:
          break;
        case 0:
          this.$delete(element, 'is_global');
          this.$delete(element, 'memo');
          break;
        default:
          this.$set(element, 'is_global', 0);
          this.$set(element, 'memo', '断货');
          break;
      }
    },
    handlePreview(id) {
      this.$emit('preview-product', id);
    },
  },
};
</script>

<template>
  <lz-dialog
    :dialogBtns="dialogBtns"
    @dialog-btn-click="handleCommonBtnCLick"
    title="新增卡券"
    :width="500"
    ref="lzDialog">
    <lz-form :form="form" :col="1" ref="lzForm" @change-select="handleChangeSelect"></lz-form>
  </lz-dialog>
</template>
<script>
import dayjs from 'dayjs';

const importStatus = [
  { label: '重复不导入', value: 0 },
  { label: '重复则更新', value: 2 },
];

export default {
  data() {
    return {
      form: [],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
    };
  },
  methods: {
    init(element) {
      this.form = [];
      setTimeout(() => {
        this.form = [
          {
            fieldName: 'add_type',
            type: 'select',
            name: '导入方式',
            value: '',
            required: true,
            options: [
              { label: '输入数量', value: 1 },
              { label: '上传excel', value: 0 },
            ],
          },
          // {
          //   fieldName: 'use_type',
          //   type: 'select',
          //   name: '出库类型',
          //   value: '',
          //   required: true,
          //   options: [
          //     // { label: '未定义', value: 0 },
          //     { label: '销售', value: 1 },
          //     { label: '赠送', value: 2 },
          //     { label: '更换', value: 3 },
          //   ],
          // },
          {
            fieldName: 'use_type',
            name: this.$route.name === 'ticket-list-recharge' ? '充值类型' : '出库类型',
            type: 'select',
            value: '',
            options: [],
            required: true,
            remote: true,
            props: {
              url: '/ticket-use-type/list',
              value: 'id',
              label: 'name',
            },
          },
          {
            name: '载体类型',
            type: 'select',
            fieldName: 'carrier_type',
            value: [],
            options: [],
            constantDict: 'carriertype',
            required: true,
          },
          // {
          //   name: '兑换码类型',
          //   type: 'select',
          //   fieldName: 'type_id',
          //   value: [],
          //   options: [],
          //   remote: true,
          //   required: true,
          //   props: {
          //     url: '/ticket-type/list',
          //     value: 'id',
          //     label: 'name',
          //   },
          // },
          { fieldName: 'add_ticket_num', type: 'number', name: '导入数量', value: '', required: true, min: 0 },
          {
            fieldName: 'attachment_id',
            responseName: 'attachment',
            type: 'upload',
            name: '上传excel',
            value: [],
            multiple: false,
            required: false,
            width: '100%',
            hide: true,
          },
          { name: '重复筛查', type: 'select', fieldName: 'type', value: '', options: importStatus, required: true },
          { fieldName: 'exchange_num', type: 'number', name: '兑换数量', value: '', required: true, min: 0 },
          { name: '充值金额', type: 'number', fieldName: 'amount', value: '', width: '100%', required: true, hide: this.$route.name !== 'ticket-list-recharge' },
          {
            name: '兑换时间',
            type: 'daterange',
            fieldName: 'begin_time||end_time',
            value: [],
            required: false,
          },
          { fieldName: 'id', type: 'text', name: 'id', value: '', hide: true },
        ];
        const time = this.form.find((r) => r.fieldName === 'begin_time||end_time');
        // this.$set(time, 'hide', this.$route.name === 'ticket-list-recharge');
        const exchangeNum = this.form.find((r) => r.fieldName === 'exchange_num');
        this.$set(exchangeNum, 'hide', this.$route.name === 'ticket-list-recharge');
        this.$refs.lzDialog.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.lzForm.setValue({
            data: {
              id: element.id,
              exchange_num: element.exchange_num,
              type_id: element.type_id,
            },
          });
          this.$set(time, 'value', time.hide ? [] : [dayjs().format('YYYY-MM-DD'), dayjs().add(3, 'year').format('YYYY-MM-DD')]);
        });
      }, 100);
    },
    async handleCommonBtnCLick() {
      const params = this.$refs.lzForm.getValue();
      if (!params) return;
      const response = await this.$baseHttp.post('/ticket/add-num', params);
      if (!response) return;
      this.$message.success('操作成功');
      this.$emit('success');
      this.$refs.lzDialog.dialogVisible = false;
    },
    handleChangeSelect(element) {
      if (element.fieldName !== 'add_type') return;
      this.form.filter((r) => ['add_ticket_num', 'attachment_id', 'type'].includes(r.fieldName)).forEach((el) => {
        if (el.fieldName === 'add_ticket_num') {
          this.$set(el, 'hide', element.value === '0');
          this.$set(el, 'required', element.value === '1');
        } else {
          this.$set(el, 'hide', element.value === '1');
          this.$set(el, 'required', element.value === '0');
        }
      });
    },
  },
};
</script>

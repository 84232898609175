<template>
  <lz-dialog title="调品记录" ref="lzDialog">
    <lz-card
      :title="parent.update_time"
      v-for="(parent, index) in list"
      :key="index">
      <div slot="right">操作人: {{ parent.nick_name }}</div>
      <template v-if="parent.goods_list_add.length > 0">
        <div class="title">产品列表(新增)</div>
        <el-tag
          :key="item.id"
          style="margin: 5px;"
          v-for="item in parent.goods_list_add">
          {{ item.title }}
        </el-tag>
      </template>
      <template v-if="parent.goods_list_del.length > 0">
        <div class="title">产品列表(删除)</div>
        <el-tag
          :key="item.id"
          style="margin: 5px"
          v-for="item in parent.goods_list_del">
          {{ item.title }}
        </el-tag>
      </template>
      <template v-if="parent.goods_add_add.length > 0">
        <div class="title">新增列表(新增)</div>
        <el-tag
          :key="item.id"
          style="margin: 5px"
          v-for="item in parent.goods_add_add ">
          {{ item.title }}
        </el-tag>
      </template>
      <template v-if="parent.goods_add_del.length > 0">
        <div class="title">新增列表(删除)</div>
        <el-tag
          :key="item.id"
          style="margin: 5px"
          v-for="item in parent.goods_add_del">
          {{ item.title }}
        </el-tag>
      </template>
    </lz-card>
  </lz-dialog>
</template>
<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  methods: {
    async init(id) {
      const response = await this.$baseHttp.get('/ticket/goods-log', {
        params: { ticket_id: id, page_size: 1000 },
      });
      if (!response) return;
      this.$refs.lzDialog.dialogVisible = true;
      this.list = response.data.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 14px;
  margin: 20px 0;
}
</style>

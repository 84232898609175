const template = [
  { label: '兑换券', value: 1 },
  { label: '礼品券', value: 2 },
];
const status = [
  { label: '是', value: 1 },
  { label: '否', value: 0 },
];

export default [
  {
    name: '基础信息',
    fieldName: 'base',
    list: [
      {
        name: '所属客户',
        type: 'select',
        fieldName: 'company_id',
        value: [],
        options: [],
        remote: true,
        required: true,
        props: {
          url: '/company/list',
          value: 'id',
          label: 'name',
        },
      },
      {
        fieldName: 'type',
        type: 'select',
        name: '订单属性',
        value: '',
        required: true,
        options: template,
      },
      {
        name: '年份',
        type: 'date',
        fieldName: 'year',
        value: '',
        format: 'yyyy',
        dateType: 'year',
      },
      {
        fieldName: 'ticket_num',
        type: 'number',
        name: '导入数量',
        value: '',
        required: true,
      },
      {
        fieldName: 'exchange_num',
        type: 'number',
        name: '兑换数量',
        value: '',
        required: true,
      },
      {
        name: '订单类型',
        type: 'select',
        fieldName: 'type_id',
        value: [],
        options: [],
        remote: true,
        required: true,
        props: {
          url: '/ticket-type/list',
          value: 'id',
          label: 'name',
        },
        disabledValue: ['9'],
      },
      {
        name: '兑换时间',
        type: 'daterange',
        fieldName: 'begin_time||end_time',
        value: [],
        width: '60%',
        required: true,
      },
    ],
  },
  {
    name: '相关信息',
    fieldName: 'other',
    list: [
      {
        fieldName: 'price_title_id',
        type: 'select',
        name: '档位',
        value: '',
        options: [],
        remote: true,
        required: true,
        props: {
          url: '/price-title/list',
          value: 'id',
          label: 'name',
        },
      },
      {
        fieldName: 'alternative_id',
        type: 'select',
        name: '备选库',
        value: '',
        options: [],
        remote: true,
        required: false,
        width: '55%',
        props: {
          url: '/ticket/list?scene_id=24',
          value: 'id',
          label: 'sale_type_name||price_title_name||webtitle',
        },
      },
      {
        name: '应用场景',
        type: 'select',
        fieldName: 'scene_id',
        value: [],
        options: [],
        remote: true,
        required: true,
        props: {
          url: '/scene/list',
          value: 'id',
          label: 'name',
        },
      },
      {
        name: '产品用途',
        type: 'select',
        fieldName: 'purpose_id',
        value: [],
        options: [],
        remote: true,
        required: true,
        props: {
          url: '/purpose/list',
          value: 'id',
          label: 'name',
        },
      },
      {
        fieldName: 'template_id',
        type: 'select',
        name: '模版',
        value: '',
        options: [],
        remote: true,
        required: true,
        props: {
          url: '/template/list',
          value: 'id',
          label: 'name',
        },
      },
      {
        fieldName: 'sale_type',
        type: 'select',
        name: '销售渠道',
        value: '',
        options: [],
        constantDict: 'saleType',
      },
      {
        fieldName: 'carrier_type',
        type: 'select',
        name: '载体类型',
        value: '',
        options: [],
        constantDict: 'carriertype',
      },
      {
        fieldName: 'ticket_line_id',
        type: 'select',
        name: '产品线',
        value: '',
        options: [],
        remote: true,
        required: true,
        props: {
          url: '/ticket-line/list',
          value: 'id',
          label: 'name',
        },
      },
      { fieldName: 'webtitle', type: 'text', name: '页面标题', value: '', required: true },
      { fieldName: 'is_tip', type: 'select', name: '温馨提示', value: '', options: status, required: true },
      // { fieldName: 'color', type: 'text', name: '背景颜色值', value: '', required: false },
      { fieldName: 'tip_content', type: 'textarea', name: '提示内容', value: '', required: false, width: '100%' },
      { fieldName: 'share_desc', type: 'textarea', name: '分享摘要', value: '', required: false, width: '100%' },
      { fieldName: 'id', type: 'text', name: 'id', value: '', hide: true },
      { fieldName: 'source_id', type: 'text', name: 'source_id', value: '', hide: true },
      // {
      //   fieldName: 'bg_id',
      //   type: 'upload',
      //   name: '前台列表背景图(可平铺)',
      //   value: [],
      //   multiple: false,
      //   required: false,
      //   width: '100%',
      // },
      // {
      //   fieldName: 'login_attachment_id',
      //   type: 'upload',
      //   name: '登录背景图(750*1206)',
      //   value: [],
      //   multiple: false,
      //   required: false,
      //   width: '100%',
      // },
      // {
      //   fieldName: 'share_attachment_id',
      //   type: 'upload',
      //   name: '分享图片(300*300)',
      //   value: [],
      //   multiple: false,
      //   required: false,
      //   width: '100%',
      // },
      { fieldName: 'content', type: 'textarea', name: 'content', value: '', hide: true },
    ],
  },
  {
    name: '财务信息',
    fieldName: 'finance',
    list: [
      {
        fieldName: 'sales_amount',
        type: 'number',
        name: '销售金额',
        value: '',
        required: true,
        disabled: true,
      },
      {
        fieldName: 'total_amount',
        type: 'number',
        name: '总金额',
        value: '',
        required: true,
        disabled: true,
      },
      {
        fieldName: 'discount',
        type: 'number',
        name: '折扣(%)',
        value: '',
        required: true,
        min: 0,
        max: 100,
      },
    ],
  },
  {
    name: '粒子信息',
    fieldName: 'lizi',
    list: [
      { fieldName: 'is_recommend', type: 'select', name: '是否推荐', value: '', options: status, required: false },
      { fieldName: 'recommend_count', type: 'number', name: '星级', value: '', max: 5, required: false },
      { fieldName: 'recommend_reason', type: 'text', name: '推荐理由', value: '', width: '100%' },
      { fieldName: 'recommend_scene', type: 'text', name: '场景', value: '', width: '100%' },
      { fieldName: 'recommend_purpose', type: 'text', name: '用途', value: '', width: '100%' },
    ],
  },
  {
    name: '产品列表',
    fieldName: 'goods-list',
    list: {
      goods_ids: [],
      add_goods_ids: [],
      goods_limit: [],
    },
  },
  {
    name: '附件',
    fieldName: 'attachment',
    list: [
      {
        fieldName: 'attachment_id',
        responseName: 'attachment',
        showCard: true,
        type: 'upload',
        name: '栏头',
        value: [],
        multiple: false,
        required: false,
        width: '100%',
      },
    ],
  },
  {
    name: '其他图片信息',
    fieldName: 'images',
    list: [
      {
        fieldName: 'bg_id',
        type: 'upload',
        name: '前台列表背景图(可平铺)',
        value: [],
        multiple: false,
        required: false,
        width: '100%',
      },
      {
        fieldName: 'logo_id',
        type: 'upload',
        name: 'logo',
        value: [],
        multiple: false,
        required: false,
        width: '100%',
      },
      {
        fieldName: 'login_attachment_id',
        type: 'upload',
        name: '登录背景图(750*1206)',
        value: [],
        multiple: false,
        required: false,
        width: '100%',
      },
      {
        fieldName: 'share_attachment_id',
        type: 'upload',
        name: '分享图片(300*300)',
        value: [],
        multiple: false,
        required: false,
        width: '100%',
      },
    ],
  },
];
